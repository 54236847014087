/* eslint-disable @typescript-eslint/camelcase */
import { all, put, takeLatest } from 'redux-saga/effects';

import { reportError, reportErrorNotifier } from '../../shared/utils/reportError';
import { dealActions } from '.';
import { api } from '../../shared/services/api';
import { ReduxAction } from '../helpers';
import { PipelineResponse } from '../../core/generatedDTO/pipeline-response';
import { mapDealDTOToDeal } from '../../core/models/deal';
import { DealNotesResponse } from '../../core/generatedDTO/deal-notes-response';
import { DealFilesResponse } from '../../core/generatedDTO/deal-files-response';
import { DealResponse } from '../../core/generatedDTO/deal-response';
import { mapDealFileDTOTODealFile } from '../../core/models/deal-file';
import { mapDealNoteDTOTODealNote } from '../../core/models/deal-note';
import { Notifier } from '../../shared/components/notifier';
import { ChartsResponse } from '../../core/generatedDTO/charts-response';
import { mapChartsDTOToCharts } from '../../core/models/charts';

function* fetchPipeline(action: ReduxAction<any>) {
  try {
    const { data }: { data: PipelineResponse } = yield api.get('/deal/pipeline-scope');
    if (data.success) {
      yield put(dealActions.fetchPipelineSuccess(data.data.map(deal => mapDealDTOToDeal(deal))));
    } else {
      yield put(dealActions.fetchPipelineFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* fetchDeal(action: ReduxAction<any>) {
  try {
    const { data }: { data: DealResponse } = yield api.get(`/deal/${action.payload}`);
    if (data.success) {
      yield put(dealActions.fetchDealSuccess(mapDealDTOToDeal(data.data)));
    } else {
      yield put(dealActions.fetchDealFail(data.data));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* fetchFiles(action: ReduxAction<any>) {
  try {
    const { data }: { data: DealFilesResponse } = yield api.get(
      `/deal/${action.payload.id}/files${action.payload.fileId ? `?parent_id=${action.payload.fileId}` : ''}`
    );
    if (data.success) {
      yield put(dealActions.fetchFilesSuccess(data.data.map(file => mapDealFileDTOTODealFile(file))));
    } else {
      yield put(dealActions.fetchFilesFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}
function* fetchNotes(action: ReduxAction<any>) {
  try {
    const { data }: { data: DealNotesResponse } = yield api.get(`/deal/${action.payload}/notes`);
    if (data.success) {
      yield put(dealActions.fetchNotesSuccess(data.data.map(note => mapDealNoteDTOTODealNote(note))));
    } else {
      yield put(dealActions.fetchNotesFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* fetchPortfolio(action: ReduxAction<any>) {
  try {
    const { data }: { data: PipelineResponse } = yield api.get('/deal/portfolio-scope');
    if (data.success) {
      yield put(dealActions.fetchPortfolioSuccess(data.data.map(deal => mapDealDTOToDeal(deal))));
    } else {
      yield put(dealActions.fetchPortfolioFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* setDealVote(action: ReduxAction<any>) {
  try {
    const { data }: { data: DealResponse } = yield api.post(`/deal/${action.payload.id}/vote`, {
      vote: action.payload.vote,
      wanted_investment_amount: action.payload.amount,
    });
    if (data.success) {
      yield put(dealActions.setDealVoteSuccess(mapDealDTOToDeal(data.data)));
      Notifier({
        type: 'success',
        title: 'Vote',
        message: `You have voted ${data.data.deal_vote ? 'yes' : 'no'} on ${data.data.deal_name}`,
      });
    } else {
      yield put(dealActions.setDealVoteFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* fetchCharts(action: ReduxAction<any>) {
  try {
    const { data }: { data: ChartsResponse } = yield api.get('/deal/portfolio-scope/charts');
    if (data.success) {
      yield put(dealActions.fetchChartsSuccess(mapChartsDTOToCharts(data.data)));
    } else {
      yield put(dealActions.fetchChartsFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

export function* watchDeal() {
  yield all([
    takeLatest(dealActions.fetchDeal, fetchDeal),
    takeLatest(dealActions.fetchNotes, fetchNotes),
    takeLatest(dealActions.fetchFiles, fetchFiles),
    takeLatest(dealActions.fetchPipeline, fetchPipeline),
    takeLatest(dealActions.fetchPortfolio, fetchPortfolio),
    takeLatest(dealActions.setDealVote, setDealVote),
    takeLatest(dealActions.fetchCharts, fetchCharts),
  ]);
}
