/* eslint-disable @typescript-eslint/camelcase */
import { DealinvestorinvestmentDTO } from '../generatedDTO/dealInvestmentDTO';

export interface DealInvestorInvestment {
  investmentAmount: number;
  dateOfAcquisition: number;
  dateOfInvestment: number;
  round: string;
  currency: string;
}

export function mapDealInvestorInvestmentToDealInvestorInvestmentDTO(
  dii: DealInvestorInvestment
): DealinvestorinvestmentDTO {
  return {
    investment_amount: dii.investmentAmount,
    date_of_acquisition: dii.dateOfAcquisition,
    date_of_investment: dii.dateOfInvestment,
    investment_round_name: dii.round,
    investment_amount_code: dii.currency,
  };
}

export function mapDealInvestorInvestmentDTOToDealInvestorInvestment(
  dii: DealinvestorinvestmentDTO
): DealInvestorInvestment {
  return {
    investmentAmount: dii.investment_amount,
    round: dii.investment_round_name,
    dateOfAcquisition: dii.date_of_acquisition * 1000,
    dateOfInvestment: dii.date_of_investment * 1000,
    currency: dii.investment_amount_code,
  };
}
