import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { authActions } from '.';
import { User } from '../../core/models/user-details';

export interface AuthState {
  auth: any;
  loading: boolean;
  resetPasswordLoading: boolean;
  updateProfileLoading: boolean;
  token: boolean | string;
  user: User | null;
  errorMessage: string | null;
  resetErrorMessage: string | null;
}

export const INITIAL_STATE: AuthState = {
  auth: null,
  loading: false,
  updateProfileLoading: false,
  resetPasswordLoading: false,
  token: '',
  user: null,
  errorMessage: null,
  resetErrorMessage: null,
};

const handleFetchAuth = (state: AuthState) => {
  state.auth = null;
  state.loading = true;
};

const handleFetchAuthSuccess = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.user = payload;
  state.loading = false;
  state.token = true;
};

const handleFetchAuthFail = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.token = payload;
  state.loading = false;
};

const handleSignIn = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.loading = true;
  state.errorMessage = null;
};

const handleSignInSuccess = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.token = payload;
  state.loading = false;
};

const handleSignInFail = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.loading = false;
  state.errorMessage = payload;
};

const handleSignOut = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.token = '';
};

const handleUpdateProfile = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.updateProfileLoading = true;
};
const handleUpdateProfileSuccess = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.updateProfileLoading = false;
  state.user = payload;
};
const handleUpdateProfileFail = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.updateProfileLoading = false;
};

const handleResetPassword = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.resetPasswordLoading = true;
  state.resetErrorMessage = '';
};
const handleResetPasswordSuccess = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.resetPasswordLoading = false;
};
const handleResetPasswordFail = (state: AuthState, { payload }: ReduxAction<any>) => {
  state.resetPasswordLoading = false;
  state.resetErrorMessage = payload;
};

const HANDLERS = {
  ...actionHandler(authActions.fetchAuth, handleFetchAuth),
  ...actionHandler(authActions.fetchAuthSuccess, handleFetchAuthSuccess),
  ...actionHandler(authActions.fetchAuthFail, handleFetchAuthFail),
  ...actionHandler(authActions.signIn, handleSignIn),
  ...actionHandler(authActions.signSuccess, handleSignInSuccess),
  ...actionHandler(authActions.signFail, handleSignInFail),
  ...actionHandler(authActions.signOut, handleSignOut),
  ...actionHandler(authActions.updateProfile, handleUpdateProfile),
  ...actionHandler(authActions.updateProfileSuccess, handleUpdateProfileSuccess),
  ...actionHandler(authActions.updateProfileFail, handleUpdateProfileFail),
  ...actionHandler(authActions.ResetPassword, handleResetPassword),
  ...actionHandler(authActions.ResetPasswordSuccess, handleResetPasswordSuccess),
  ...actionHandler(authActions.ResetPasswordFail, handleResetPasswordFail),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
