import React, { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import LoginOutlined from '@ant-design/icons/LoginOutlined';
import { authActions } from '../../modules/auth';
import {
  selectAuthError,
  selectAuthLoading,
  selectResetError,
  selectResetPasswordLoading,
} from '../../modules/auth/auth.selectors';
import signinMessages from './signin.messages';
import Logo from '../../images/pentugramLogo.svg';
import './signin.scss';
import { ErrorContainer } from './signin.styles';
import { CustomButtonComponent } from '../../shared/components/customButton/customButton.component';

export interface SigninComponentProps {
  children?: ReactNode;
}

export const SigninComponent = ({ children }: SigninComponentProps) => {
  // const email = 'amerkhaldi@outlook.com';
  // const password = 'secret2';
  const dispatch = useDispatch();
  // ===================
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [view, setView] = useState(1);
  const [logo] = useState({ url: Logo, h: 78, w: 78 });
  const loading = useSelector(selectAuthLoading);
  const handleSubmit = (event: { preventDefault: () => void }) => {
    dispatch(authActions.signIn({ email, password }));
    event.preventDefault();
  };
  const handleReset = (event: { preventDefault: () => void }) => {
    dispatch(authActions.ResetPassword({ email }));
    event.preventDefault();
  };
  const handleChangeView = e => {
    e.preventDefault();
    setView(view === 1 ? 2 : 1);
  };
  const errorMessage = useSelector(selectAuthError);
  const resetError = useSelector(selectResetError);
  const resetLoading = useSelector(selectResetPasswordLoading);
  const { formatMessage } = useIntl();
  return (
    <div className="Signin">
      <Helmet title={formatMessage(signinMessages.pageTitle)} />
      {view === 1 ? (
        <form className="login-form" onSubmit={handleSubmit}>
          <div>
            <img src={`${logo.url}`} alt="Pentugram" height={`${logo.h}`} width={`${logo.w}`} />
          </div>
          <h1>Angel Portal</h1>
          {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
          <div className="form-field">
            <i className="fas fa-user" />
            <input
              type="email"
              name="email"
              id="email"
              className="form-field"
              placeholder=" "
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-field">
            <i className="fas fa-lock" />
            <input
              type="password"
              name="password"
              id="password"
              className="form-field"
              placeholder=" "
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password</label>
          </div>
          <CustomButtonComponent
            label={loading ? ' ' : 'Login'}
            disabled={loading}
            icon={<LoginOutlined />}
            isLoading={loading}
            className="primary"
            size="large"
            block
            htmlType="submit"
          />
          <div>
            <a className="login-form-forgot" onClick={handleChangeView}>
              Forgot password ?
            </a>
          </div>
        </form>
      ) : (
        <form className="login-form" onSubmit={handleReset}>
          <div>
            <img src={`${logo.url}`} alt="Pentugram" height={`${logo.h}`} width={`${logo.w}`} />
          </div>
          <h1>Investor Portal</h1>
          <Typography.Title level={4}>Reset Your Password</Typography.Title>
          <Typography.Text strong>Send password via email</Typography.Text>
          {resetError && <ErrorContainer>{resetError}</ErrorContainer>}
          <div className="form-field">
            <i className="fas fa-user" />
            <input
              type="email"
              name="email"
              id="email"
              className="form-field"
              placeholder=" "
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <CustomButtonComponent
            loading={resetLoading}
            label="Reset Password"
            className="primary"
            size="large"
            block
            htmlType="submit"
          />
          <div>
            <a className="login-form-forgot" onClick={handleChangeView}>
              Back
            </a>
          </div>
        </form>
      )}
    </div>
  );
};
