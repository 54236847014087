import { ContactDTO } from '../generatedDTO/contactsDTO';

export interface Contact {
  id: number;
  first: string;
  fullname: string;
  last: string;
  title: string;
  company: string;
  email: string;
  phone: string;
  facebook: string;
  linkedin: string;
  twitter: string;
  skype: string;
  createdAt: number;
  updatedAt: number;
  image: string;
}

export function mapContactDTOToContact(c: ContactDTO): Contact {
  return {
    id: c.contact_id,
    first: c.first_name,
    last: c.last_name,
    fullname: `${c.first_name || ''} ${c.last_name || ''}`,
    title: c.title || 'No title',
    company: c.company,
    email: c.email,
    phone: c.phone,
    facebook: c.facebook,
    linkedin: c.linkedin,
    twitter: c.twitter,
    skype: c.skype,
    createdAt: c.created_at,
    updatedAt: c.updated_at,
    image: c.image,
  };
}
