/* eslint-disable @typescript-eslint/camelcase */
import { DealNoteDTO } from '../generatedDTO/dealNoteDTO';

export interface DealNote {
  id: number;
  createdAt: number;
  content: string;
}

export function mapDealNoteToDealNoteDTO(note: DealNote): DealNoteDTO {
  return {
    note_id: note.id,
    created_at: note.createdAt,
    content: note.content,
  };
}

export function mapDealNoteDTOTODealNote(note: DealNoteDTO): DealNote {
  return {
    id: note.note_id,
    content: note.content,
    createdAt: note.created_at * 1000,
  };
}
