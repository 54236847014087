import { createGlobalStyle } from 'styled-components';
import { Color, Font } from './theme.constants';
import { themeFont } from './getters';
import { colors } from './theme';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${themeFont(Font.PRIMARY)};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
    
  body {
    width: 100%;
    background: #f9f9f9 !important;
    font-family: 'Epilogue' !important;
    user-select: none;
    color: ${colors[Color.LIGHT_BLACK]};
  }

  .custom-scrollbar {
    margin-left: 30px;
    float: left;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .flex-start-wrap {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .flex-start {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .hover-40a9ff {
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }

  .mr-2 {
    margin-right: 10px;
  }

  .ml-2 {
    margin-left: 10px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }
`;
