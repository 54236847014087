/* eslint-disable @typescript-eslint/camelcase */
import { UserDTO } from '../generatedDTO/userDetailsDTO';
import { mapWorkspaceDataToWorkspaceDTO, mapWorkspaceDTOtoWorkspaceData, Workspace } from './workspace';

export interface User {
  name: string;
  id: number;
  email: string;
  phone: string;
  image: string;
  workspaceId: number;
  workspaceName: string;
  workspaceLogo: string;
  workspaceCurrency: string;
  isRegisteredCompleted: boolean;
  workspaces: Workspace[];
}

export function mapUserDetailsToUserDTO(user: User): UserDTO {
  return {
    fullname: user.name,
    investor_id: user.id,
    email: user.email,
    investor_image: user.image,
    workspace_id: user.workspaceId,
    workspace_name: user.workspaceName,
    workspace_logo: user.workspaceLogo,
    investor_phone: user.phone,
    workspace_currency: user.workspaceCurrency,
    is_registered_completed: user.isRegisteredCompleted,
    workspaces: user.workspaces.map(workspace => mapWorkspaceDataToWorkspaceDTO(workspace)),
  };
}

export function mapUserDTOTOUserDetails(user: UserDTO): User {
  return {
    name: user.fullname,
    image: user.investor_image,
    id: user.investor_id,
    workspaceCurrency: user.workspace_currency,
    email: user.email,
    workspaceName: user.workspace_name,
    phone: user.investor_phone,
    workspaceId: user.workspace_id,
    workspaceLogo: user.workspace_logo,
    isRegisteredCompleted: user.is_registered_completed,
    workspaces: user.workspaces
      .map(workspace => mapWorkspaceDTOtoWorkspaceData(workspace))
      .filter(workspace => workspace.id !== user.workspace_id),
  };
}
