import styled from 'styled-components';
import { colors } from '../../theme/theme';
import { Color } from '../../theme/theme.constants';

export const ErrorContainer = styled.div`
  color: ${colors[Color.ERROR]};
  width: 100%;
  background-color: ${colors[Color.LIGHT_RED]};
  padding: 10px;
  border-radius: 5px;
  margin: 0 10px;
`;
