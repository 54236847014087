import { actionCreator } from '../helpers';

const createAction = actionCreator('DEAL');

export const fetchDeal = createAction<any>('FETCH_DEAL');
export const fetchDealSuccess = createAction<any>('FETCH_DEAL_SUCCESS');
export const fetchDealFail = createAction<any>('FETCH_DEAL_FAIL');

export const fetchNotes = createAction<any>('FETCH_NOTES');
export const fetchNotesSuccess = createAction<any>('FETCH_NOTES_SUCCESS');
export const fetchNotesFail = createAction<any>('FETCH_NOTES_FAIL');

export const fetchFiles = createAction<any>('FETCH_FILES');
export const fetchFilesSuccess = createAction<any>('FETCH_FILES_SUCCESS');
export const fetchFilesFail = createAction<any>('FETCH_FILES_FAIL');

export const fetchPipeline = createAction<void>('FETCH_PIPELINE');
export const fetchPipelineSuccess = createAction<any>('FETCH_PIPELINE_SUCCESS');
export const fetchPipelineFail = createAction<any>('FETCH_PIPELINE_FAIL');

export const fetchPortfolio = createAction<void>('FETCH_PORTFOLIO');
export const fetchPortfolioSuccess = createAction<any>('FETCH_PORTFOLIO_SUCCESS');
export const fetchPortfolioFail = createAction<any>('FETCH_PORTFOLIO_FAIL');

export const setDealVote = createAction<void>('DEAL_VOTE');
export const setDealVoteSuccess = createAction<any>('DEAL_VOTE_SUCCESS');
export const setDealVoteFail = createAction<any>('DEAL_VOTE_FAIL');

export const fetchCharts = createAction<void>('FETCH_CHARTS');
export const fetchChartsSuccess = createAction<any>('FETCH_CHARTS_SUCCESS');
export const fetchChartsFail = createAction<any>('FETCH_CHARTS_FAIL');
