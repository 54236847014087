/* eslint-disable react/jsx-indent */
import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { AppComponent as App } from './app.component';
import { DEFAULT_LOCALE, appLocales, translationMessages } from '../i18n';
import { ROUTES } from './app.constants';
import { asyncComponent } from '../shared/utils/asyncComponent';
import { Signin } from './signin';
import { selectAuthToken } from '../modules/auth/auth.selectors';
//<-- IMPORT ROUTE -->

const Home = asyncComponent(() => import('./home'), 'Home');
const NotFound = asyncComponent(() => import('./notFound'), 'NotFound');

const MainRoutes = () => {
  return (
    <Switch>
      <Route>
        <Home />
      </Route>
    </Switch>
  );
};

const MatchedLanguageComponent = () => {
  const match = useRouteMatch();
  const token = useSelector(selectAuthToken);
  return (
    <App>
      <Switch>
        <Route exact path={`${match.url}${ROUTES.signin}`}>
          <Signin />
        </Route>
        <Route
          path={`${match.path}${ROUTES.home}`}
          render={({ location }) =>
            token ? (
              <MainRoutes />
            ) : (
              <Redirect
                to={{
                  pathname: `${DEFAULT_LOCALE}${ROUTES.signin}`,
                  state: { from: location },
                }}
              />
            )
          }
        />
        {/* <-- INJECT ROUTE --> */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </App>
  );
};

export default hot(() => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={DEFAULT_LOCALE} />
      </Route>

      <Route path={`/:lang(${appLocales.join('|')})`}>
        <MatchedLanguageComponent />
      </Route>

      <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>
        <HelmetProvider>
          <Route path="/notFound">
            <NotFound />
          </Route>
        </HelmetProvider>
      </IntlProvider>
    </Switch>
  );
});
