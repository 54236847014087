import axios from 'axios';
import { store } from '../../index';
import { startupActions } from '../../modules/startup';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

if (!baseUrl) {
  throw new Error('REACT_APP_BASE_API_URL env is missing');
}

const api = axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

api.interceptors.response.use(function(request) {
  const isSwitchAccount = request.config.url?.endsWith('switch-account');
  const isMe = request.config.url?.endsWith('me');
  const backendCurrentWorkspace = request.headers['pentugram-angel-investor-workspace-id'];
  const currentWorkspace = store.getState().auth?.user?.workspaceId;
  if (!isSwitchAccount && !isMe) {
    if (Number(backendCurrentWorkspace) !== currentWorkspace) {
      store.dispatch(startupActions.startup());
    }
  }
  return request;
});
export { api };
