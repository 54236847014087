import React from 'react';
import notification, { IconType, NotificationPlacement } from 'antd/lib/notification';

import history from '../../utils/history';
import { CustomButtonComponent } from '../customButton/customButton.component';

interface NotifierProps {
  type: IconType;
  title: string;
  message: string;
  placement?: NotificationPlacement;
  redirect?: any;
}

export const NotifierComponent = ({ type, title, message, placement, redirect }: NotifierProps) => {
  const notifType = type || 'open';
  const key = `open${Date.now()}`;
  const actionButton = (
    <CustomButtonComponent
      label="Show details"
      style={{ width: '150px' }}
      onClick={() => {
        notification.close(key);
        history.push(redirect);
      }}
    />
  );

  notification[notifType]({
    message: title,
    description: message,
    placement: placement || 'bottomRight',
    btn: redirect ? actionButton : null,
    key,
  });
};
