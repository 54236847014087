/* eslint-disable @typescript-eslint/camelcase */
import { DealFileDTO } from '../generatedDTO/dealFileDTO';

export interface DealFile {
  id: number;
  url: string;
  size: number;
  is_leaf: boolean;
  title: string;
  createdAt: number;
}

export function mapDealFileDTOTODealFile(file: DealFileDTO): DealFile {
  return {
    id: file.file_id,
    url: file.url,
    size: file.size,
    createdAt: file.created_at,
    is_leaf: file.is_leaf,
    title: file.title || GetFilename(file.url),
  };
}

export function mapDealFileToDealFileDTO(file: DealFile): DealFileDTO {
  return {
    file_id: file.id,
    url: file.url,
    size: file.size,
    created_at: file.createdAt,
  };
}

function GetFilename(url: string): string {
  if (url) {
    return url
      .split('.')
      .slice(0, -1)
      .join('.');
  }
  return 'Folder';
}
