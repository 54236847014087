import { actionCreator } from '../helpers';

const createAction = actionCreator('AUTH');

export const fetchAuth = createAction<void>('FETCH_AUTH');
export const fetchAuthSuccess = createAction<any>('FETCH_AUTH_SUCCESS');
export const fetchAuthFail = createAction<any>('FETCH_AUTH_FAIL');

export const signIn = createAction<any>('SIGN_IN');
export const signSuccess = createAction<any>('SIGN_IN_SUCCESS');
export const signFail = createAction<any>('SIGN_IN_FAIL');

export const signOut = createAction<void>('SIGN_OUT');

export const switchAccount = createAction<any>('SWITCH_ACCOUNT');
export const switchAccountSuccess = createAction<void>('SWITCH_ACCOUNT_SUCCESS');
export const switchAccountFail = createAction<void>('SWITCH_ACCOUNT_FAIL');

export const updateProfile = createAction<any>('UPDATE_PROFILE');
export const updateProfileSuccess = createAction<any>('UPDATE_PROFILE_SUCCESS');
export const updateProfileFail = createAction<void>('UPDATE_PROFILE_FAIL');

export const ResetPassword = createAction<void>('RESET_PASSWORD');
export const ResetPasswordSuccess = createAction<any>('RESET_PASSWORD_SUCCESS');
export const ResetPasswordFail = createAction<any>('RESET_PASSWORD_FAIL');
