/* eslint-disable @typescript-eslint/camelcase */
import { WorkspaceDTO } from '../generatedDTO/workspaceDTO';

export interface Workspace {
  id: number;
  name: string;
  logo: string | null;
}

export function mapWorkspaceDTOtoWorkspaceData(workspace: WorkspaceDTO): Workspace {
  return {
    id: workspace.workspace_id,
    name: workspace.workspace_name,
    logo: workspace.workspace_logo,
  };
}

export function mapWorkspaceDataToWorkspaceDTO(workspace: Workspace): WorkspaceDTO {
  return {
    workspace_name: workspace.name,
    workspace_id: workspace.id,
    workspace_logo: workspace.logo,
  };
}
