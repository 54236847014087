import React, { Fragment, ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ResponsiveThemeProvider as ThemeProvider } from '../shared/components/responsiveThemeProvider';
import { translationMessages } from '../i18n';
import { GlobalStyle } from '../theme/global';
import messages from './app.messages';
import { theme } from '../theme/theme';
import { localesSelectors } from '../modules/locales';
import { useStartup } from './useStartup';
import { useLanguageFromParams } from './useLanguageFromParams';
import { selectAuthLoading } from '../modules/auth/auth.selectors';

export interface AppComponentProps {
  children?: ReactNode;
}

export const AppComponent = ({ children }: AppComponentProps) => {
  useStartup();
  useLanguageFromParams();

  const language = useSelector(localesSelectors.selectLocalesLanguage);
  const loadingAuth = useSelector(selectAuthLoading);
  if (loadingAuth) {
    return (
      <div>
        <div className="root-loader" style={{ flexDirection: 'column' }}>
          <div className="root-warpper">
            <div className="root-inner" />
          </div>
          <div className="root-text">LOADING</div>
        </div>
      </div>
    );
  }

  if (!language) {
    return null;
  }

  return (
    <IntlProvider key={language} locale={language} messages={translationMessages[language]}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Fragment>
            <FormattedMessage {...messages.pageTitle}>
              {(pageTitle: string) => <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle} />}
            </FormattedMessage>

            <GlobalStyle />
            {React.Children.only(children)}
          </Fragment>
        </ThemeProvider>
      </HelmetProvider>
    </IntlProvider>
  );
};
