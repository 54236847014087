/* eslint-disable @typescript-eslint/camelcase */
import { all, put, takeLatest } from 'redux-saga/effects';

import { reportError, reportErrorNotifier } from '../../shared/utils/reportError';
import { authActions } from '.';
import { api } from '../../shared/services/api';
import { ReduxAction } from '../helpers';
import { startupActions } from '../startup';
import history from '../../shared/utils/history';
import { Notifier } from '../../shared/components/notifier';
import { UserResponse } from '../../core/generatedDTO/user-response';
import { mapUserDTOTOUserDetails } from '../../core/models/user-details';

function* signIn(action: ReduxAction<any>) {
  try {
    const { data } = yield api.post('/authentication/login', {
      email: action.payload.email,
      password: action.payload.password,
    });
    if (data.success) {
      yield put(authActions.signSuccess(data.data.token));
      yield (localStorage.token = data.data.token);
      yield put(startupActions.startup());
      history.push('/');
    } else {
      yield put(authActions.signFail(data.message));
      reportErrorNotifier(data.message);
      history.push('signin');
    }
  } catch (error) {
    reportError(error);
  }
}

function* signOut(action: ReduxAction<any>) {
  try {
    yield localStorage.clear();
    history.push('/en/signin');
  } catch (error) {
    reportError(error);
  }
}

function* switchAccount(action: ReduxAction<any>) {
  try {
    const { data } = yield api.post('/me/switch-account', {
      workspace_id: action.payload,
    });
    if (data.success) {
      yield put(authActions.switchAccountSuccess());
      yield put(startupActions.startup());
    } else {
      yield put(authActions.switchAccountFail());
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* updateProfile(action: ReduxAction<any>) {
  try {
    const { data }: { data: UserResponse } = yield api.put('/me/update-profile', {
      ...action.payload,
    });
    if (data.success) {
      yield put(authActions.updateProfileSuccess(mapUserDTOTOUserDetails(data.data)));
      Notifier({ type: 'info', title: 'Profile', message: 'Profile was updated!' });
      history.push('/');
    } else {
      yield put(authActions.updateProfileFail());
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

function* resetPassword(action: ReduxAction<any>) {
  try {
    const { data } = yield api.post('/me/reset-password', {
      ...action.payload,
    });
    if (data.success) {
      yield put(authActions.ResetPasswordSuccess(data.data));
      Notifier({ type: 'success', title: 'Password reset', message: 'An email was sent to you with new password!' });
    } else {
      yield put(authActions.ResetPasswordFail(data.message));
      reportErrorNotifier(data.message);
    }
  } catch (error) {
    reportError(error);
  }
}

export function* watchAuth() {
  yield all([
    takeLatest(authActions.signIn, signIn),
    takeLatest(authActions.switchAccount, switchAccount),
    takeLatest(authActions.signOut, signOut),
    takeLatest(authActions.updateProfile, updateProfile),
    takeLatest(authActions.ResetPassword, resetPassword),
  ]);
}
