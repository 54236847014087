import { Button, Tooltip } from 'antd';
import React, { ReactNode } from 'react';

export interface CustomButtonComponentProps {
  children?: ReactNode;
  [key: string]: any;
}

export const CustomButtonComponent = ({ children, ...props }: CustomButtonComponentProps) => {
  if (props.tooltip) {
    return (
      <Tooltip placement={props.placement} title={props.tooltip}>
        <Button
          icon={props.icon}
          loading={props.loading}
          className={props.className}
          size={props.size}
          type={props.type}
          block={props.block}
          htmlType={props.htmlType}
          onClick={props.onClick}
          shape={props.shape}
          disabled={props.disabled}
        >
          {props.label}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      icon={props.icon}
      loading={props.loading}
      className={props.className}
      size={props.size}
      type={props.type}
      block={props.block}
      htmlType={props.htmlType}
      onClick={props.onClick}
      shape={props.shape}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};
