import { Notifier } from '../components/notifier';

/**
 * Generic function for error reporting.
 * Use it in all sagas, so you can later send errors to Sentry.
 * @param error
 * @returns void
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const reportError = (error: string, message: string): void => {};

export const reportErrorNotifier = (message: string): void => {
  Notifier({ type: 'error', title: 'Request failed', message });
};
