import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { dealActions } from '.';
import { Deal } from '../../core/models/deal';
import { DealNote } from '../../core/models/deal-note';
import { DealFile } from '../../core/models/deal-file';
import { authActions } from '../auth';
import { Charts } from '../../core/models/charts';

export interface DealState {
  deal: Deal | null;
  notes: DealNote[] | [];
  files: DealFile[] | [];
  pipeline: Deal[] | [];
  portfolio: Deal[] | [];
  charts: Charts | null;
  loading: boolean;
  voteLoading: boolean;
  notesLoading: boolean;
  filesLoading: boolean;
  chartsLoading: boolean;
}

export const INITIAL_STATE: DealState = {
  deal: null,
  notes: [],
  files: [],
  pipeline: [],
  portfolio: [],
  loading: false,
  charts: null,
  voteLoading: false,
  notesLoading: false,
  chartsLoading: false,
  filesLoading: false,
};

const handleFetchDeal = (state: DealState) => {
  state.loading = true;
};

const handleFetchDealSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.deal = payload;
  state.loading = false;
};

const handleFetchDealFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.deal = null;
  state.loading = false;
};

const handleFetchNotes = (state: DealState) => {
  state.notesLoading = true;
};

const handleFetchNotesSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.notes = payload;
  state.notesLoading = false;
};

const handleFetchNotesFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.notes = [];
  state.notesLoading = false;
};

const handleFetchFiles = (state: DealState) => {
  state.filesLoading = true;
};

const handleFetchFilesSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.files = payload;
  state.filesLoading = false;
};

const handleFetchFilesFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.files = [];
  state.filesLoading = false;
};

const handleFetchPipeline = (state: DealState) => {
  state.loading = true;
};

const handleFetchPipelineSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.pipeline = payload;
  state.loading = false;
};

const handleFetchPipelineFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.pipeline = [];
  state.portfolio = [];
  state.loading = false;
};

const handleFetchPortfolio = (state: DealState) => {
  state.loading = true;
};

const handleFetchPortfolioSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.portfolio = payload;
  state.loading = false;
};

const handleFetchPortfolioFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.pipeline = [];
  state.portfolio = [];
  state.loading = false;
};

const handleSetDealVote = (state: DealState) => {
  state.voteLoading = true;
};

const handleSetDealVoteSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  // replace existing deal with new updated deal
  // state.pipeline = [...state.pipeline, payload];
  state.voteLoading = false;
  state.pipeline = state.pipeline.map(deal => (deal.id === payload.id ? payload : deal));
  state.deal = state.deal ? payload : null;
};

const handleSetDealVoteFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.voteLoading = false;
};

const handleSwitchAccountSuccess = (state: DealState, { payload }: ReduxAcion<any>) => {
  state.pipeline = [];
  state.portfolio = [];
  state.notes = [];
  state.files = [];
  state.deal = null;
};

const handleFetchCharts = (state: DealState) => {
  state.chartsLoading = true;
};

const handleFetchChartsSuccess = (state: DealState, { payload }: ReduxAction<any>) => {
  state.charts = payload;
  state.chartsLoading = false;
};

const handleFetchChartsFail = (state: DealState, { payload }: ReduxAction<any>) => {
  state.charts = null;
  state.chartsLoading = false;
};

const HANDLERS = {
  ...actionHandler(dealActions.fetchDeal, handleFetchDeal),
  ...actionHandler(dealActions.fetchDealSuccess, handleFetchDealSuccess),
  ...actionHandler(dealActions.fetchDealFail, handleFetchDealFail),
  ...actionHandler(dealActions.fetchNotes, handleFetchNotes),
  ...actionHandler(dealActions.fetchNotesSuccess, handleFetchNotesSuccess),
  ...actionHandler(dealActions.fetchNotesFail, handleFetchNotesFail),
  ...actionHandler(dealActions.fetchFiles, handleFetchFiles),
  ...actionHandler(dealActions.fetchFilesSuccess, handleFetchFilesSuccess),
  ...actionHandler(dealActions.fetchFilesFail, handleFetchFilesFail),
  ...actionHandler(dealActions.fetchPipeline, handleFetchPipeline),
  ...actionHandler(dealActions.fetchPipelineSuccess, handleFetchPipelineSuccess),
  ...actionHandler(dealActions.fetchPipelineFail, handleFetchPipelineFail),
  ...actionHandler(dealActions.fetchPortfolio, handleFetchPortfolio),
  ...actionHandler(dealActions.fetchPortfolioSuccess, handleFetchPortfolioSuccess),
  ...actionHandler(dealActions.fetchPortfolioFail, handleFetchPortfolioFail),
  ...actionHandler(dealActions.setDealVote, handleSetDealVote),
  ...actionHandler(dealActions.setDealVoteSuccess, handleSetDealVoteSuccess),
  ...actionHandler(dealActions.setDealVoteFail, handleSetDealVoteFail),
  ...actionHandler(dealActions.fetchCharts, handleFetchCharts),
  ...actionHandler(dealActions.fetchChartsSuccess, handleFetchChartsSuccess),
  ...actionHandler(dealActions.fetchChartsFail, handleFetchChartsFail),
  ...actionHandler(authActions.switchAccountSuccess, handleSwitchAccountSuccess),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
