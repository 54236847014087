import { pathOr } from 'ramda';
import { mapCurrencyToSymbol } from '../enum/money';
/* eslint-disable @typescript-eslint/camelcase */
import { DealDTO } from '../generatedDTO/dealDTO';
import { Contact, mapContactDTOToContact } from './deal-contact';
import {
  DealInvestorInvestment,
  mapDealInvestorInvestmentDTOToDealInvestorInvestment,
  mapDealInvestorInvestmentToDealInvestorInvestmentDTO,
} from './deal-investment';

export interface Deal {
  id: number;
  name: string;
  vote: boolean;
  image: string;
  pipeline: boolean;
  linkedAt: number;
  activity: string;
  website: string;
  investments?: DealInvestorInvestment[];
  sameCurrencyInvestments?: boolean;
  totalInvestment?: string | undefined;
  country?: string;
  createdAt?: number;
  currency?: string;
  symbol?: string;
  size?: number;
  description?: string | null;
  type?: string | null;
  contacts?: Contact[];
  commitement?: number | null;
  commitementCurrency?: string | null;
  canVote?: boolean | null;
}

export function mapDealtoDealDTO(deal: Deal): DealDTO {
  return {
    deal_id: deal.id,
    deal_image: deal.image,
    deal_name: deal.name,
    deal_linked_at: deal.linkedAt,
    deal_pipeline: deal.pipeline,
    deal_vote: deal.vote,
    deal_website: deal.website,
    deal_country: deal.country,
    deal_size: deal.size,
    deal_activity: deal.activity,
    deal_currency: deal.currency,
    created_at: deal.createdAt,
    deal_investor_investments: deal.investments?.map(investment =>
      mapDealInvestorInvestmentToDealInvestorInvestmentDTO(investment)
    ),
    wanted_investment_amount: null,
    wanted_investment_amount_currency: null,
    deal_vote_enabled: deal?.canVote,
  };
}

export function mapDealDTOToDeal(deal: DealDTO): Deal {
  const firstCurrency = pathOr('', ['deal_investor_investments', '0', 'investment_amount_code'])(deal);
  return {
    id: deal.deal_id,
    name: deal.deal_name,
    vote: deal.deal_vote,
    image: deal.deal_image,
    pipeline: deal.deal_pipeline,
    website: deal.deal_website,
    linkedAt: deal.deal_linked_at,
    country: deal.deal_country,
    createdAt: deal.created_at,
    size: deal.deal_size,
    currency: deal.deal_currency,
    type: deal.type_of_investment,
    description: deal.company_description,
    commitement: deal?.wanted_investment_amount,
    commitementCurrency: deal?.wanted_investment_amount_currency || deal.deal_currency,
    activity: deal.deal_activity,
    symbol: mapCurrencyToSymbol(deal.wanted_investment_amount_currency || 'USD'),
    sameCurrencyInvestments: !checkCurrencies(deal, firstCurrency),
    totalInvestment: deal.investments_total,
    investments: deal.deal_investor_investments?.map(investment =>
      mapDealInvestorInvestmentDTOToDealInvestorInvestment(investment)
    ),
    contacts: deal.deal_contacts?.map(contact => mapContactDTOToContact(contact)),
    canVote: deal?.deal_vote_enabled || false,
  };
}

export function checkCurrencies(deal: DealDTO, currency: string): boolean | undefined {
  return deal.deal_investor_investments?.some(inv => currency !== inv.investment_amount_code);
}
