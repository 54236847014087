import { createSelector } from '@reduxjs/toolkit';
import { pathOr, prop } from 'ramda';

import { GlobalState } from '../reducers';

const emptyChart = { name: '', data: [] };

export const selectDealDomain = (state: GlobalState) => state.deal;
export const selectDeal = createSelector(selectDealDomain, prop('deal'));
export const selectNotes = createSelector(selectDealDomain, prop('notes'));
export const selectFiles = createSelector(selectDealDomain, prop('files'));
export const selectPipline = createSelector(selectDealDomain, prop('pipeline'));
export const selectPortfolio = createSelector(selectDealDomain, prop('portfolio'));
export const selectDealLoading = createSelector(selectDealDomain, prop('loading'));
export const selectVoteLoading = createSelector(selectDealDomain, prop('voteLoading'));
export const selectNotesLoading = createSelector(selectDealDomain, prop('notesLoading'));
export const selectFilesLoading = createSelector(selectDealDomain, prop('filesLoading'));
export const selectCharts = createSelector(selectDealDomain, prop('charts'));
export const selectChartsLoading = createSelector(selectDealDomain, prop('chartsLoading'));
export const selectCurrencyChartData = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'currencyChart'])
);
export const selectCurrencyChartName = createSelector(
  selectDealDomain,
  pathOr('', ['charts', 'currencyChart', 'name'])
);
export const selectCountryChartData = createSelector(selectDealDomain, pathOr(emptyChart, ['charts', 'countryChart']));
export const selectCountryChartName = createSelector(selectDealDomain, pathOr('', ['charts', 'countryChart', 'name']));
export const selectActivityData = createSelector(selectDealDomain, pathOr(emptyChart, ['charts', 'activityChart']));
export const selectActivityName = createSelector(selectDealDomain, pathOr('', ['charts', 'activityChart', 'name']));
export const selectInvestmentByActivity = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'investmentByActivity'])
);
export const selectInvestmentByCountry = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'investmentByCountry'])
);
export const selectInvestmentByRound = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'investmentByRound'])
);
export const selectNDealsByRound = createSelector(selectDealDomain, pathOr(emptyChart, ['charts', 'NDealsByRound']));
export const selectNDealsByInvestmentType = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'NDealsByInvestmentType'])
);
export const selectInvestmentByType = createSelector(
  selectDealDomain,
  pathOr(emptyChart, ['charts', 'investmentByType'])
);
