import { createSelector } from '@reduxjs/toolkit';
import { pathOr, prop } from 'ramda';

import { GlobalState } from '../reducers';

export const selectAuthDomain = (state: GlobalState) => state.auth;

export const selectAuthToken = createSelector(selectAuthDomain, prop('token'));
export const selectAuthLoading = createSelector(selectAuthDomain, prop('loading'));
export const selectUser = createSelector(selectAuthDomain, prop('user'));
export const selectWorkspace = createSelector(selectAuthDomain, pathOr(null, ['user', 'workspaceName']));
export const selectWorkspaceId = createSelector(selectAuthDomain, pathOr(null, ['user', 'workspaceId']));
export const selectWorkspaceLogo = createSelector(selectAuthDomain, pathOr(null, ['user', 'workspaceLogo']));
export const selectAuthError = createSelector(selectAuthDomain, prop('errorMessage'));
export const selectWorkspaces = createSelector(selectAuthDomain, pathOr([], ['user', 'workspaces']));
export const selectProfileLoading = createSelector(selectAuthDomain, prop('updateProfileLoading'));
export const selectResetPasswordLoading = createSelector(selectAuthDomain, prop('resetPasswordLoading'));
export const selectResetError = createSelector(selectAuthDomain, prop('resetErrorMessage'));
export const selectIsProfileFinished = createSelector(
  selectAuthDomain,
  pathOr(false, ['user', 'isRegisteredCompleted'])
);
export const selectUserEmail = createSelector(selectAuthDomain, pathOr('', ['user', 'email']));
export const selectUserPhone = createSelector(selectAuthDomain, pathOr('', ['user', 'phone']));
export const selectUserImage = createSelector(selectAuthDomain, pathOr('', ['user', 'image']));
export const selectUserName = createSelector(selectAuthDomain, pathOr('', ['user', 'name']));
export const selectCompanyCurrency = createSelector(selectAuthDomain, pathOr('', ['user', 'workspaceCurrency']));
