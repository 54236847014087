import { put, all, takeLatest } from 'redux-saga/effects';
import { startupActions } from '.';
import { mapUserDTOTOUserDetails } from '../../core/models/user-details';
import { UserResponse } from '../../core/generatedDTO/user-response';
import { DEFAULT_LOCALE } from '../../i18n';
import { ROUTES } from '../../routes/app.constants';
import { api } from '../../shared/services/api';
import history from '../../shared/utils/history';
import { reportError } from '../../shared/utils/reportError';
import { authActions } from '../auth';

export function* handleStartup() {
  try {
    yield put(authActions.fetchAuth());
    const { data }: { data: UserResponse } = yield api.get('/me');
    if (data.success) {
      yield put(authActions.fetchAuthSuccess(mapUserDTOTOUserDetails(data.data)));
    } else {
      yield put(authActions.fetchAuthFail(data.message));
      history.push(`/${DEFAULT_LOCALE}${ROUTES.signin}`);
    }
  } catch (error) {
    reportError(error);
  }
}

export function* watchStartup() {
  yield all([takeLatest(startupActions.startup, handleStartup)]);
}
