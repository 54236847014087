import { ChartsDTO, ChartDTO, ValueDTO } from '../generatedDTO/chartsDTO';

export interface Charts {
  countryChart: Chart;
  activityChart: Chart;
  currencyChart: Chart;
  investmentByActivity: Chart;
  investmentByCountry: Chart;
  investmentByRound: Chart;
  NDealsByRound: Chart;
  NDealsByInvestmentType: Chart;
  investmentByType: Chart;
}

interface Chart {
  name: string;
  isAmount: boolean;
  data: Value[];
}

interface Value {
  name: string;
  value: number;
}

export function mapChartsDTOToCharts(charts: ChartsDTO): Charts {
  return {
    countryChart: mapChartDTOtoChart(charts.country_chart),
    currencyChart: mapChartDTOtoChart(charts.currency_chart),
    activityChart: mapChartDTOtoChart(charts.activity_chart),
    investmentByActivity: mapChartDTOtoChart(charts.investment_amount_by_activity, true),
    investmentByCountry: mapChartDTOtoChart(charts.investment_amount_by_country, true),
    investmentByRound: mapChartDTOtoChart(charts.investment_amount_by_round, true),
    NDealsByRound: mapChartDTOtoChart(charts.number_deals_per_round_chart),
    NDealsByInvestmentType: mapChartDTOtoChart(charts.number_deals_per_type_of_investment),
    investmentByType: charts.investment_amount_by_investment_type
      ? mapChartDTOtoChart(charts.investment_amount_by_investment_type, true)
      : { name: '', data: [], isAmount: true },
  };
}

export function mapChartDTOtoChart(chart: ChartDTO, isAmount = false): Chart {
  return {
    name: chart.name,
    isAmount: isAmount,
    data: chart.data.map(value => mapChartDataValueDTOToChartDataValue(value)),
  };
}

export function mapChartDataValueDTOToChartDataValue(value: ValueDTO): Value {
  return {
    name: value.label,
    value: Number(value.value),
  };
}
